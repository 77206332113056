import React from 'react';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  return (
    <Helmet>
      <meta name='robots' content='noindex' />
      <meta
        http-equiv='Refresh'
        content='0; URL=https://carcoatingrefine.com/'
      />
    </Helmet>
  );
};

export default NotFoundPage;
